
import { Helmet } from "react-helmet-async";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material";

import Alertavel from "../../../Components/Alertavel";
import ResponsiveDialog from "../../../Components/Dialog/Dialog";

import { User } from "../../../Models/User";
import { Company } from "../../../Models/Organization/Company";
import { Property } from "../../../Models/Organization/Property";
import { CategoryFinances } from "../../../Models/Finances/Category";

import { ErrorToStringFix, showLoading } from "../../../Services/globalFunction.js"
import { dataLimitation, dataParentCategory, dataTypeCategory } from "../constants";

import "../../../App.css";
import AutocompleteSelect from "../../../Components/Selects/AutocompleteSelect";


const AddCategory = ({ module }) => {
  const [title, setTitle] = useState("CADASTRO DE CATEGORIAS");

  const [dataSubsidiaries, setDataSubsidiaries] = useState([]);
  const [dataCategories, setDataCategories] = useState([]);
  const [dataProperty, setDataProperty] = useState([]);

  const [disabledFarm, setDisabledFarm] = useState(false);
  const [disabledCategory, setDisabledCategory] = useState(false);
  const [disabledValidation, setDisabledValidation] = useState(false);
  const [disabledSubsidiaries, setDisabledSubsidiaries] = useState(false);
  const [disabledTypeCategory, setDisabledTypeCategory] = useState(false);
  const [disabledTypeLimitation, setDisabledTypeLimitation] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [contentAlert, setContentAlert] = useState("");
  const [contentModal, setContentModal] = useState("");

  const [btnReset, setBtnReset] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const user = User.searchUser();
  const companyId = user.empresa_id;
  const token = user.token;

  const { state } = location;

  const [formData, setFormData] = useState({
    id: "",
    nome: "",
    ativa: 'S',
    descricao: "",
    validacao: null,
    categoria_pai: null,
    tipo_categoria: null,
    tipo_limitacao: null,
    filiais_limitadas: [],
    fazendas_limitadas: [],
  });

  const Load_Categories = useCallback(async () => {
    const categories = new CategoryFinances(token);
    const searchCategories = await categories.SearchCategories().then(response => {
      if (!response.erro) {
        const allCategories = categories => categories.flatMap(({ subcategorias, ...categoryData }) => [categoryData, ...(subcategorias ? allCategories(subcategorias) : [])]);
        return allCategories(response);
      } else {
        setContentAlert("Você não possui permissão para consultar as categorias. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    setDataCategories(searchCategories);
  }, [token]);

  const Load_Category = useCallback(async (category_id) => {

    const category = new CategoryFinances(token);
    const searchCategory = await category.SearchCategory(category_id).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para consultar a categoria. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    return searchCategory;
  }, [token]);

  const Load_Farm = useCallback(async () => {
    const parameters = {
      modulo: module.modulo ?? 0,
      ativa: 'S'
    }

    let Farm = new Property(token);
    let Properties = await Farm.SearchProperty(parameters).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para buscar as propriedades. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });
    setDataProperty(Properties);
  }, [module.modulo, token]);

  const Load_Subsidiaries = useCallback(async () => {
    const parameters = {
      modulo: module.modulo ?? 0
    }

    let Subsidiary = new Company(token);
    let Subsidiaries = await Subsidiary.SearchSubsidiaries(companyId, parameters).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para buscar as filiais. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });
    setDataSubsidiaries(Subsidiaries);
  }, [companyId, module.modulo, token]);

  const handleCategoryValidationChange = (event) => {
    if (event) {
      if (event.id === "S") {
        setFormData({ ...formData, validacao: event.id, categoria_pai: null, fazendas_limitadas: [], filiais_limitadas: [] });
        Load_Categories();
      } else {
        setDisabledTypeCategory(false);
        setDisabledTypeLimitation(false);
        setFormData({ ...formData, validacao: event.id, tipo_categoria: null, tipo_limitacao: null, categoria_pai: null, fazendas_limitadas: [], filiais_limitadas: [] });
      }
    } else {
      setFormData({ ...formData, validacao: null, categoria_pai: null, tipo_categoria: null, tipo_limitacao: null, fazendas_limitadas: [], filiais_limitadas: [] });
    }
  }

  const handleCategoryChange = async (event) => {
    if (event) {
      setFormData({ ...formData, tipo_categoria: null, tipo_limitacao: null });

      const { tipo_categoria, tipo_limitacao, filiais_limitadas, fazendas_limitadas } = await Load_Category(event.id)

      setDisabledTypeCategory(true);

      if (tipo_limitacao === 'FAZENDA') {
        setDataProperty(fazendas_limitadas);
        setDisabledTypeLimitation(true);
      } else if (tipo_limitacao === "FILIAL") {
        setDataProperty(filiais_limitadas);
        setDisabledTypeLimitation(true);
        setDisabledSubsidiaries(true);
      } else {
        setDisabledTypeLimitation(false);
      }

      setFormData({ ...formData, categoria_pai: event.id, tipo_categoria: tipo_categoria, tipo_limitacao: tipo_limitacao });
    } else {
      setFormData({ ...formData, tipo_categoria: null, tipo_limitacao: null });
    }
  }

  const handleLimitationChange = (event) => {
    if (event) {
      setFormData({ ...formData, tipo_limitacao: event.id });
      if (event.id === 'FAZENDA') {
        Load_Farm();
      } else if (event.id === 'FILIAL') {
        Load_Subsidiaries();
      } else {
        return;
      }
    } else {
      setFormData({ ...formData, tipo_limitacao: null, fazendas_limitadas: [], filiais_limitadas: [] });
    }
  }

  const handleSubsidiariesChange = (event) => {
    if (event) {

      let updateSubsidiaries = [];

      event.forEach((val, i) => {
        updateSubsidiaries.push({ id: val.id, nome: val.nome });
      });

      setFormData((prevData) => ({ ...prevData, filiais_limitadas: updateSubsidiaries }));
    }
  }

  const handlePropertiesChange = (event) => {
    if (event) {
      let updateProperties = [];

      event.forEach((val, i) => {
        updateProperties.push({ id: val.id, nome: val.nome });
      });

      setFormData((prevData) => ({ ...prevData, fazendas_limitadas: updateProperties }));
    }
  }

  const validateForm = () => {
    const requiredFields = [
      'nome',
      'validacao',
      'tipo_categoria',
      'tipo_limitacao',
    ];

    if (formData.tipo_limitacao === 'FAZENDA') {
      requiredFields.push('fazendas_limitadas');
    } else if (formData.tipo_limitacao === 'FILIAL') {
      requiredFields.push('filiais_limitadas');
    }

    if (formData.validacao === 'S') {
      requiredFields.push('categoria_pai');
    }

    const isAnyFieldInvalid = requiredFields.some((field) => !formData[field]);

    if (isAnyFieldInvalid) {
      return "Existem campos obrigatórios que não foram preenchidos.";
    }

    return null;
  };

  const saveOnClick = (event) => {
    setOpenAlert(false);

    const dataId = event.currentTarget.getAttribute("data-id");
    const errorMessage = validateForm();

    const showSuccessAlert = (message) => {
      setOpenModal(true);
      setContentModal(
        <Grid container alignContent="center" alignItems="center" sx={{ height: "100%" }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Alertavel title="" type="success" open={true} content={message} />
          </Grid>
        </Grid>
      );
    };

    if (errorMessage) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setContentAlert(errorMessage);
      setOpenAlert(true);
      return;
    }

    showLoading(true);
    var result = null;
    if (dataId) {
      const lista_fazendas = formData.fazendas_limitadas.map((val) => val.id);
      const lista_filiais = formData.filiais_limitadas.map((val) => val.id);

      const dataToSubmit = { ...formData, fazendas_limitadas: lista_fazendas, filiais_limitadas: lista_filiais };

      const finances = new CategoryFinances(token);
      result = finances.UpdateCategory(dataId, dataToSubmit);

    } else {

      const lista_fazendas = formData.fazendas_limitadas.map((val) => val.id);
      const lista_filiais = formData.filiais_limitadas.map((val) => val.id);

      delete formData.id;
      delete formData.validacao;

      const dataToSubmit = { ...formData, fazendas_limitadas: lista_fazendas, filiais_limitadas: lista_filiais };

      const finances = new CategoryFinances(token);
      result = finances.SaveCategory(dataToSubmit);
    }
    showLoading(false);

    if (result.erro !== undefined) {
      let mensagem = ErrorToStringFix(result.erro)
      setContentAlert(mensagem);
      setOpenAlert(true);
    } else {
      showSuccessAlert("Dados Atualizados com sucesso");
    }

  }

  const modalClose = () => {
    setOpenModal(false);
    handleResetPage();
  };

  const handleResetPage = useCallback(() => {
    const routes = module.rotas.find(element => element.tipo_rota === 'CADASTRO' && element.referencia === 'categoria');
    navigate(`/financeiro${routes.rota}`);

    setTitle("CADASTRO DE CATEGORIAS");

    setFormData({
      id: "",
      nome: "",
      ativa: 'S',
      descricao: "",
      validacao: null,
      categoria_pai: null,
      tipo_categoria: null,
      tipo_limitacao: null,
      filiais_limitadas: [],
      fazendas_limitadas: [],
    });

    setBtnReset('');
  }, [module.rotas, navigate]);

  useEffect(() => {
    const { tipo, data: dataEdit } = state || {};

    const loadData = async () => {
      const { categoria_pai, tipo_limitacao, filiais_limitadas, fazendas_limitadas } = dataEdit;

      let validacao = categoria_pai !== null ? 'S' : 'N';

      setTitle("EDITANDO CATEGORIA - #" + dataEdit.id);

      const resetButton = (
        <Grid item lg={8} md={12} sm={12} xs={12}>
          <Grid container mb={2}>
            <Grid item lg={3} md={4} sm={12} xs={12}>
              <Button className="successBtn" variant="contained" fullWidth onClick={handleResetPage}>
                Cadastro de Categoria
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );

      setBtnReset(resetButton);

      if (validacao === 'S') {
        Load_Categories();
      }

      if (tipo_limitacao === 'FILIAL') {
        setDisabledTypeLimitation(true);
        setDisabledSubsidiaries(false);
        Load_Subsidiaries();
      } else if (tipo_limitacao === 'FAZENDA') {
        setDisabledTypeLimitation(true);
        setDisabledFarm(false);
        Load_Farm();
      }

      setDisabledCategory(true);
      setDisabledValidation(true);
      setDisabledTypeCategory(true);
      setDisabledTypeLimitation(true);

      const formData = {
        id: dataEdit.id,
        nome: dataEdit.nome,
        ativa: "S",
        descricao: dataEdit.descricao,
        validacao: validacao,
        categoria_pai: categoria_pai,
        tipo_categoria: dataEdit.tipo_categoria,
        tipo_limitacao: tipo_limitacao,
        filiais_limitadas: filiais_limitadas ? filiais_limitadas.map(val => ({ id: val.id, nome: val.nome })) : [],
        fazendas_limitadas: fazendas_limitadas ? fazendas_limitadas.map(val => ({ id: val.id, nome: val.nome })) : [],
      };

      setFormData(formData);
    }

    if (tipo === "edicao" && dataEdit) {
      loadData();
    } else {
      handleResetPage();
    }
  }, [state, Load_Categories, Load_Subsidiaries, Load_Farm, handleResetPage]);

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>Financeiro</title>
      </Helmet>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={2} textAlign="center">
        <Typography className="titles-pages" variant="h4">{title}</Typography>
      </Grid>

      {btnReset}

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
        <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />

        <Card className="card-form">
          <CardContent>

            <Grid container>
              <Typography variant="h6" className="titles">Nome</Typography>
            </Grid>

            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="nome"
                  type="text"
                  value={formData.nome}
                  label="Nome da Categoria"
                  InputLabelProps={{ required: true }}
                  onChange={(event) => setFormData({ ...formData, nome: event.target.value })}
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  multiline
                  rows={4}
                  id="descricao"
                  label="Descrição da categoria"
                  value={formData.descricao}
                  onChange={(event) => setFormData({ ...formData, descricao: event.target.value })}
                />
              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>

        <Card className="card-form">
          <CardContent>

            <Grid container>
              <Typography variant="h6" className="titles">Categoria</Typography>
            </Grid>

            <Grid container spacing={1}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AutocompleteSelect
                  id="validacao"
                  label="Possui categoria pai ?"
                  isMultiple={false}
                  required={true}
                  disabled={disabledValidation}
                  data={dataParentCategory}
                  value={formData.validacao}
                  onChange={handleCategoryValidationChange}
                />
              </Grid>

              {formData.validacao === 'S' && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <AutocompleteSelect
                    id="categoria"
                    label="Categoria"
                    isMultiple={false}
                    required={true}
                    disabled={disabledCategory}
                    data={dataCategories}
                    value={formData.categoria_pai}
                    onChange={handleCategoryChange}
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
        <Card className="card-form">
          <CardContent>

            <Grid container>
              <Typography variant="h6" className="titles">Tipo de Categoria</Typography>
            </Grid>

            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <AutocompleteSelect
                  id="tipo_categoria"
                  label="Tipo Categoria"
                  isMultiple={false}
                  required={true}
                  disabled={disabledTypeCategory}
                  data={dataTypeCategory}
                  value={formData.tipo_categoria}
                  onChange={(event) => setFormData({ ...formData, tipo_categoria: event ? event.id : null })}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
        <Card className="card-form">
          <CardContent>

            <Grid container>
              <Typography variant="h6" className="titles">Limitação</Typography>
            </Grid>

            <Grid container spacing={1}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AutocompleteSelect
                  id="limitacao"
                  label="Limitação"
                  isMultiple={false}
                  required={true}
                  disabled={disabledTypeLimitation}
                  data={dataLimitation}
                  value={formData.tipo_limitacao}
                  onChange={handleLimitationChange}
                />
              </Grid>

              {formData.tipo_limitacao === 'FAZENDA' && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <AutocompleteSelect
                    id="fazenda"
                    label="Fazenda"
                    isMultiple={true}
                    required={true}
                    disabled={disabledFarm}
                    data={dataProperty}
                    value={formData.fazendas_limitadas}
                    onChange={handlePropertiesChange}
                  />
                </Grid>
              )}

              {formData.tipo_limitacao === 'FILIAL' && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <AutocompleteSelect
                    id="filial"
                    label="Filial"
                    isMultiple={true}
                    required={true}
                    disabled={disabledSubsidiaries}
                    data={dataSubsidiaries}
                    value={formData.filiais_limitadas}
                    onChange={handleSubsidiariesChange}
                  />
                </Grid>
              )}

            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={1} textAlign="center">
        <Button className="successBtn" variant="contained" onClick={(event) => saveOnClick(event)} data-id={formData.id || ""}>Gravar</Button>
      </Grid>

      <ResponsiveDialog
        content={contentModal}
        isFullscreen={false}
        openModal={openModal}
        onClose={modalClose}
        title="Financeiro"
      />

    </Grid>
  )
}

export default AddCategory;
import { useEffect, useState } from "react";
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";


import Sidebar from "../../Components/Sidebar";
import { User } from "../../Models/User";

import { routesCalculators } from "./routes";

import Plantability from "./Pages/Agronomic/Plantability";

import "../../App.css";
import Initial from "./Pages/Inicio";

export default function Calculators() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const initialActiveButton = () => {
    const pathname = location.pathname;

    if (pathname.includes("/inicio")) return 1;
    if (pathname.includes("/inicio")) return 2;
    if (pathname.includes("/inicio")) return 3;

    return 1;
  };

  const [activeButton, setActiveButton] = useState(initialActiveButton);

  const navigateToPage = (page, state) => {
    navigate(`/calculadoras/${page}`, state);
  };

  const validateUser = () => {
    const user = User.searchUser();
    if (user?.isLoggedIn()) {
      setIsLoggedIn(true);
    }
  };

  const buttonClick = (id) => {
    setActiveButton(id);
    switch (id) {
      case 1:
        navigateToPage('inicio');
        break;
      case 2:
        navigateToPage('calculadora-plantabilidade');
        break;
      case 3:
        navigateToPage('inicio');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    validateUser();
  });

  return (
    <Sidebar isLoggedIn={isLoggedIn} links={routesCalculators} activeButton={activeButton} onButtonClick={buttonClick}>
      <Routes>
        <Route path="/inicio" element={<Initial />} />
        <Route path="/calculadora-plantabilidade" element={<Plantability buttonClick={buttonClick} />} />
      </Routes>
    </Sidebar>
  );
}
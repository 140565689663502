

import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import DownloadIcon from '@mui/icons-material/Download';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, TextField, Tooltip, Typography } from "@mui/material";

import Alertavel from "../../../Components/Alertavel";
import AutocompleteSelect from "../../../Components/Selects/AutocompleteSelect";

import { PieChart } from '../../../Components/ApexCharts';

import { dataParentCategory } from "../constants";

import { getCurrentDate, showLoading } from "../../../Services/globalFunction";

import { User } from "../../../Models/User";
import { Finances } from "../../../Models/Finances/Finances";

import "../../../App.css";

const Dashboard = ({ module }) => {
  const title = "Dashboard"

  const [openAccordion, setOpenAccordion] = useState(true);
  const [dashboardContent, setDashboardContent] = useState('');
  const [contentAlert, setContentAlert] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const user = User.searchUser();
  const token = user.token;

  /** Estado para armazenar os dados do formulário. */
  const [relForm, setRelForm] = useState({
    data_inicial: getCurrentDate(0, 30, 'subtrai'),
    data_final: getCurrentDate(0, 0, ''),
    ativo: 'S'
  });

  const checkForm = () => {
    return relForm.data_inicial &&
      relForm.data_final &&
      relForm.ativo
  }

  const LoadPDF = async () => {
    setOpenAlert(false);

    showLoading(true);
    var parameters = { data_inicial: relForm.data_inicial, data_final: relForm.data_final, ativo: relForm.ativo }
    if (checkForm()) {
      const finances = new Finances(token);
      const response = await finances.DownloadPDF(parameters).then(response => {
        if (!response.erro) {
          return response;
        } else {
          setContentAlert("Você Não possui permissão para listar as finanças. Por favor, entre em contato com um administrador para obter assistência.");
          setOpenAlert(true);
          return [];
        }
      });
      showLoading(false);

      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    } else {
      setContentAlert("É necessário informar todos os dados para fazer a filtragem");
      setOpenAlert(true);
    }
  }

  const buildDashboard = dados => {
    setDashboardContent('')
    
    //----Preparação dos dados de entradas
    let categories_entradas = dados.entradas.map(obj => obj.categoria_nome)
    let data_entradas = dados.entradas.map(obj => obj.valor)

    //----Preparação dos dados de saidas
    let categories_saidas = dados.saidas.map(obj => obj.categoria_nome)
    let data_saidas = dados.saidas.map(obj => obj.valor)

    setDashboardContent(
      <Grid container item spacing={2} lg={8} md={12} sm={12} xs={12} justifyContent="center">
        <br />

        <Grid item lg={12} md={12} sm={12} xs={12} mb={4}>

          <Grid container justifyContent="center" alignContent="center" alignItems="center" spacing={1}>

            <Grid item lg={11} md={11} sm={11} xs={11}>
              <Typography variant="h5">
                Resumo
              </Typography>
            </Grid>
            
            <Grid item lg={1} md={1} sm={1} xs={1}>
              <Button size="small" variant="outlined" endIcon={<DownloadIcon />} style={{float: "inline-end"}} onClick={LoadPDF}>PDF</Button>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} mb={4}>
              <Typography variant="h6">Total entrada: R$ {dados.total_entradas.toLocaleString('pt-br', {minimumFractionDigits: 2})}</Typography>
              <Typography variant="h6">Total saída: R$ {dados.total_saidas.toLocaleString('pt-br', {minimumFractionDigits: 2})}</Typography>
              <Typography variant="h6">Total: R$ {dados.total.toLocaleString('pt-br', {minimumFractionDigits: 2})}</Typography>
            </Grid>

            <Grid item lg={6} md={12} sm={12} xs={12} mb={4}>
              <PieChart id="entradas" title="Entradas" labels={categories_entradas} series={data_entradas} />
            </Grid>

            <Grid item lg={6} md={12} sm={12} xs={12} mb={4}>
              <PieChart id="saidas" title="Saídas" labels={categories_saidas} series={data_saidas} />
            </Grid>

          </Grid>

        </Grid>

      </Grid>
    )
  }

  const Execute = async () => {
    setOpenAlert(false);

    var parameters = { data_inicial: relForm.data_inicial, data_final: relForm.data_final, ativo: relForm.ativo }
    if (checkForm()) {
      await Load_Finances(false, parameters)
    } else {
      setContentAlert("É necessário informar todos os dados para fazer a filtragem");
      setOpenAlert(true);
    }
  }

  const Load_Finances = useCallback(async (execute_initial, parameters) => {
    setOpenAlert(false)

    if (execute_initial) {
      parameters = {
        tipo: 0,
        data_inicial: getCurrentDate(0, 30, 'subtrai'),
        data_final: getCurrentDate(0, 0, ''),
        ativo: 'S'
      }
    }

    showLoading(true);
    const finances = new Finances(token);
    const dados = await finances.DashboardFinances(parameters).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você Não possui permissão para listar as finanças. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });
    showLoading(false);

    if(dados.possui_dados) {
      setOpenAccordion(false)
      buildDashboard(dados)
    } else if(!execute_initial) {
      setOpenAccordion(true)
      setDashboardContent(
        <Grid container item spacing={2} lg={8} md={12} sm={12} xs={12} justifyContent="center">

          <Grid item lg={12} md={12} sm={12} xs={12} mb={4}>
            Não existe dados para os filtros selecionados!
          </Grid>
          
        </Grid>)
    }

  }, [token])

  useEffect(() => {
    Load_Finances(true, null)
  }, [Load_Finances])

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={2} textAlign="center">
        <Typography className="titles-pages" variant="h4">{title}</Typography>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
        <Accordion expanded={openAccordion} onChange={(e) => { setOpenAccordion(!openAccordion)}}>
          <AccordionSummary expandIcon={<FontAwesomeIcon className="font-icons-list" icon={faChevronDown} />}>
            <Grid container>
              <Typography className="titles" variant="h6">Selecione os dados abaixo:</Typography>
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container justifyContent="center" alignContent="center" alignItems="center" spacing={1}>
              <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />

              <Grid item lg={4} md={4} sm={4} xs={12}>
                <TextField
                  type="date"
                  id="data_inicial"
                  value={relForm.data_inicial}
                  label="Data Inicial"
                  error={!relForm.data_inicial}
                  InputLabelProps={{ shrink: true, required: true }}
                  onChange={(event) => setRelForm({ ...relForm, data_inicial: event.target.value })}
                />
              </Grid>

              <Grid item lg={4} md={4} sm={4} xs={12}>
                <TextField
                  type="date"
                  id="data_final"
                  value={relForm.data_final}
                  label="Data Final"
                  error={!relForm.data_final}
                  InputLabelProps={{ shrink: true, required: true }}
                  onChange={(event) => setRelForm({ ...relForm, data_final: event.target.value })}
                />
              </Grid>

              <Grid item lg={2} md={6} sm={12} xs={12}>
                <AutocompleteSelect
                  id="ativo"
                  label="Ativo ?"
                  isMultiple={false}
                  required={true}
                  disabled={false}
                  data={dataParentCategory}
                  value={relForm.ativo}
                  onChange={(event) => setRelForm({ ...relForm, ativo: event.id })}
                />
              </Grid>

              <Grid className="grid-filter-button" item lg={2} md={2} sm={6} xs={12}>
                <Tooltip title="Filtrar">
                  <Button className="btnfilter successBtn" fullWidth onClick={Execute} variant="contained"><FontAwesomeIcon icon={faFilter} /> Filtrar</Button>
                </Tooltip>
              </Grid>
            </Grid>

          </AccordionDetails>
        </Accordion>
      </Grid>

      {dashboardContent}

    </Grid>
  )
}

export default Dashboard;
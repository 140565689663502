import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import "../../../App.css";

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Accordion from "@mui/material/Accordion";
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Card, CardContent, InputAdornment } from '@mui/material';

import Tables from '../../../Components/Table';
import Alertavel from '../../../Components/Alertavel';
import ConfirmDialog from '../../../Components/Dialog/Confirm';
import ResponsiveDialog from '../../../Components/Dialog/Dialog';
import AutocompleteSelect from "../../../Components/Selects/AutocompleteSelect";

import { dataUnit } from '../constants';

import { User } from '../../../Models/User';
import { AgronomicPrescription } from '../../../Models/AgronomicPrescription/AgronomicPrescription';
import { getCurrentDate, showLoading } from '../../../Services/globalFunction';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faEye, faTrash, faFilter, faFilePdf, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const headers = ['Fazenda', 'Data Inicial', 'Data Final', 'Cultura'];

const ListAgronomicPrescription = ({ module }) => {
  const [agronomicPrescription, setAgronomicPrescription] = useState([]);
  const [dataAgronomicPrescription, setDataAgronomicPrescription] = useState([]);

  const [deleteItemId, setDeleteItemId] = useState(null);

  const [dataIni, setDataIni] = useState(getCurrentDate(0, 30, 'subtrai'));
  const [dataEnd, setDataEnd] = useState(getCurrentDate(0, 0, ''));

  const [fullscreen, setFullscreen] = useState(false);
  const [title, setTitle] = useState("");

  const [contentModal, setContentModal] = useState('');
  const [contentAlert, setContentAlert] = useState("");
  const [contentDialog, setContentDialog] = useState('');

  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const user = User.searchUser();
  const token = user.token;
  const navigate = useNavigate();

  const LoadAgronomicPrescription = useCallback(async () => {

    const parameters = {
      data_inicial: dataIni,
      data_final: dataEnd
    };

    showLoading(true);
    const prescription = new AgronomicPrescription(token);
    const Prescription = await prescription.SearchAgronomicPrescription(parameters).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para listar as receitas. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });
    showLoading(false);

    setDataAgronomicPrescription(Prescription);

  }, [token, dataIni, dataEnd]);

  const handleView = (id) => {
    let fields = [];

    const dataView = dataAgronomicPrescription.find((val) => val.id === id);
    const { talhoes, itens, data_inicial, data_final, fazenda_nome, cultura_nome, tipo_maquinario, capacidade_tanque, vazao_hectare, velocidade_vento, temperatura_min, temperatura_max, umidade_ar, hora_inicial, hora_final, obs } = dataView;

    const maxLength = talhoes.reduce((total, val) => total + parseFloat(val.tamanho), 0);

    const mapUnit = (unidadeId) => {
      const unidade = dataUnit.find((unidade) => unidade.id === unidadeId);
      return unidade ? unidade.nome : '';
    };

    talhoes.forEach((val, i) => {
      fields.push({ id: i, nome: val.nome });
    })

    const getEndAdornmentText = (val) => {
      if (["ML", "L"].includes(val.unidade_item)) {
        return "Litros";
      } else if (["GRAMA", "KG"].includes(val.unidade_item)) {
        return "Quilogramas";
      } else {
        return "";
      }
    };

    const renderAccordionDetails = () => {
      return itens.map((val, index) => (
        <Grid key={val.id} item marginBottom={2} lg={12} md={12} sm={12} xs={12}>
          <Accordion defaultExpanded id={"panel-header-" + index} aria-controls={"panel-content-" + index} className="accordion-list">
            <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
              <Grid container>
                <Grid item lg={10} md={10} sm={10} xs={10} display="flex">
                  <Typography className="titles" variant="h6">Produto {index + 1}</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
                <Grid className="grid-item" item lg={4} md={4} sm={12} xs={12}>
                  <TextField className="disabled-textField" disabled label="Grupo" value={val.grupo_nome} />
                </Grid>
                <Grid className="grid-item" item lg={4} md={4} sm={12} xs={12}>
                  <TextField className="disabled-textField" disabled label="Produto" value={val.item_nome} />
                </Grid>
                <Grid className="grid-item" item lg={3.8} md={4} sm={12} xs={12}>
                  <TextField className="disabled-textField" disabled label="Ordem de cauda" value={val.ordem_adicao} />
                </Grid>
                <Grid className="grid-item" item lg={4} md={4} sm={12} xs={12}>
                  <TextField className="disabled-textField" disabled label="Volume por Hectare" value={val.dose_hectare} />
                </Grid>
                <Grid className="grid-item" item lg={4} md={4} sm={12} xs={12}>
                  <TextField className="disabled-textField" disabled label="Unidade de Medida" value={mapUnit(val.unidade_item)} />
                </Grid>
                <Grid className="grid-item" item lg={3.8} md={4} sm={12} xs={12}>
                  <TextField className="disabled-textField" disabled label="Dose Tanque" value={val.dose_tanque} InputProps={{ endAdornment: (<InputAdornment position="end">{getEndAdornmentText(val)}</InputAdornment>) }} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ));
    };

    const content = () => {
      return (
        <Grid container justifyContent="center">

          <Helmet>
            <title>Listagem de Receitas</title>
          </Helmet>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={2} textAlign="center">
            <Typography className="titles-pages" variant="h4">Receitas</Typography>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
            <Card className="card-form">
              <CardContent>

                <Grid container>
                  <Typography variant="h6" className="titles">Data e Hora</Typography>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField type="date" className="disabled-textField" disabled value={data_inicial} label="Data Inicial" />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField type="date" className="disabled-textField" disabled value={data_final} label="Data Final" />
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField type="time" className="disabled-textField" disabled value={hora_inicial} label="Hora Inicial" />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField type="time" className="disabled-textField" disabled value={hora_final} label="Hora Final" />
                  </Grid>
                </Grid>

              </CardContent>
            </Card>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
            <Card className="card-form">
              <CardContent>
                <Grid container>
                  <Typography variant="h6" className="titles">Cultura</Typography>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField className="disabled-textField" disabled value={cultura_nome} label="Cultura" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
            <Card className="card-form">
              <CardContent>

                <Grid item lg={12} md={12} sm={12} xs={12} marginTop={2}>
                  <Typography variant="h6" className="titles">Propriedade</Typography>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item lg={5} md={4} sm={4} xs={12}>
                    <TextField className="disabled-textField" disabled value={fazenda_nome} label="Fazenda" />
                  </Grid>

                  <Grid item lg={5} md={4} sm={4} xs={12}>
                    <AutocompleteSelect isMultiple={true} id="talhoesView" label="Talhoes" data={fields} value={fields} disabled={true} />
                  </Grid>

                  <Grid item lg={2} md={4} sm={4} xs={12}>
                    <TextField className="disabled-textField" disabled value={maxLength} label="Área" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
            <Card className="card-form">
              <CardContent>

                <Grid item lg={12} md={12} sm={12} xs={12} marginTop={2}>
                  <Typography variant="h6" className="titles">Equipamento</Typography>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item lg={4} md={12} sm={12} xs={12}>
                    <TextField className="disabled-textField" disabled value={tipo_maquinario} label="Tipo de Maquinário" />
                  </Grid>

                  <Grid item lg={4} md={12} sm={12} xs={12}>
                    <TextField className="disabled-textField" disabled value={capacidade_tanque} label="Capacidade do Tanque" InputProps={{
                      endAdornment: <InputAdornment position="start">Litros</InputAdornment>
                    }} />
                  </Grid>

                  <Grid item lg={4} md={12} sm={12} xs={12}>
                    <TextField className="disabled-textField" disabled value={vazao_hectare} label="Vazão do Maquinário" InputProps={{ endAdornment: <InputAdornment position="start">Litros</InputAdornment> }} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid container spacing={2} item lg={8} md={12} sm={12} xs={12} justifyContent="center">
            {renderAccordionDetails()}
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={1}>
            <Card className="card-form">
              <CardContent>
                <Grid item lg={12} md={12} sm={12} xs={12} marginTop={2}>
                  <Typography variant="h6" className="titles">Condições Climáticas</Typography>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField className="disabled-textField" disabled value={temperatura_min} label="Temp. Mínima" />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField className="disabled-textField" disabled value={temperatura_max} label="Temp. Máxima" />
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField className="disabled-textField" disabled value={velocidade_vento} label="Velocidade Máx. Vento" />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField className="disabled-textField" disabled value={umidade_ar} label="Umidade do Ar" />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField multiline rows={4} className="disabled-textField" disabled value={obs} label="Observação" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      );
    }

    setTitle("LISTAGEM DE RECEITA - #" + id);
    setContentModal(content);
    setFullscreen(true);
    setOpenModal(true);
  };

  const handleEdit = (id) => {
    let dataEdit = dataAgronomicPrescription.find((val) => val.id === id);

    const routes = module.rotas.find(element => element.tipo_rota === 'EDICAO' && element.referencia === 'receitas');
    if (routes) {
      navigate(`/receituario-agronomico${routes.rota}`, { state: { tipo: 'edicao', data: dataEdit } });
    } else {
      setContentAlert("Não é possível editar o registro, tente novamente mais tarde !!");
      setOpenAlert(true);
    }
  };

  const handleDownloadPdf = async (id) => {
    const prescription = new AgronomicPrescription(token);
    const response = await prescription.GenerateAgronomicPrescriptionPDF(id);

    const blob = new Blob([response], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  };

  // Click botão deletar
  const handleDelete = (id) => {
    setDeleteItemId(id);
    setTitle("Deletar Registro");
    setContentDialog("Você tem certeza que deseja excluir esta Receita?");
    setOpenDialog(true);
  };

  // Click confirmar remoção
  const handleDeleteConfirmed = async () => {
    const user = User.searchUser();
    const token = user.token;

    const remove = new AgronomicPrescription(token);
    remove.DeleteAgronomicPrescription(deleteItemId);

    setAgronomicPrescription([]);
    setDataAgronomicPrescription([]);
    setOpenDialog(false);
  };

  // Click cancelar remoção
  const handleDeleteCanceled = () => {
    setOpenDialog(false);
  };

  //Change Data Inicial
  const handleInitialDate = (event) => {
    const selectedValue = event.target.value
    setDataIni(selectedValue);
  };

  //Change Data Final
  const handleEndDate = (event) => {
    const selectedValue = event.target.value
    setDataEnd(selectedValue);
  };

  // Botões da tabela
  const actions = (id) => {
    return (
      <ButtonGroup size="small" variant="text" aria-label="text button group">
        <Tooltip title="Visualizar">
          <Button className="ListBtn" onClick={() => handleView(id)}><FontAwesomeIcon className="font-icons-list" icon={faEye} /></Button>
        </Tooltip>
        <Tooltip title="Editar">
          <Button className="ListBtn" onClick={() => handleEdit(id)}><FontAwesomeIcon className="font-icons-list" icon={faPen} /></Button>
        </Tooltip>
        <Tooltip title="Deletar">
          <Button className="ListBtn" onClick={() => handleDelete(id)}><FontAwesomeIcon className="font-icons-list" icon={faTrash} /></Button>
        </Tooltip>
        <Tooltip title="Download PDF">
          <Button className="ListBtn" onClick={() => handleDownloadPdf(id)}><FontAwesomeIcon className="font-icons-list" icon={faFilePdf} /></Button>
        </Tooltip>
      </ButtonGroup>
    );
  };

  // Botão de filtros
  const filterClick = async (e) => {
    setOpenAlert(false);
    if (dataIni && dataEnd) {
      await LoadAgronomicPrescription();
    } else {
      setContentAlert("É necessário informar a Data Inicial e a Data Final para fazer a filtragem");
      setOpenAlert(true);
    }
  };

  const modalClose = () => {
    setOpenModal(false);
    setFullscreen(false);
  };

  useEffect(() => {

    const validateRecipes = () => {

      const prescriptions = dataAgronomicPrescription.map((val) => {
        const AgronomicPrescriptionDataTable = {
          id: val.id,
          fazenda: val.fazenda_nome,
          data_inicial: val.data_inicial,
          data_final: val.data_final,
          cultura: val.cultura_nome
        };

        return AgronomicPrescriptionDataTable;
      });

      setAgronomicPrescription(prescriptions);
    };

    validateRecipes();

  }, [dataAgronomicPrescription]);

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>Listagem de Receitas</title>
      </Helmet>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={2} textAlign="center">
        <Typography className="titles-pages" variant="h4">LISTAGEM DE RECEITAS</Typography>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
        <Accordion defaultExpanded id="panel-header" aria-controls="panel-content">
          <AccordionSummary expandIcon={<FontAwesomeIcon className="font-icons-list" icon={faChevronDown} />}>
            <Grid container>
              <Typography className="titles" variant="h6">Selecione os dados abaixo:</Typography>
            </Grid>
          </AccordionSummary>

          <AccordionDetails>

            <Grid container justifyContent="center" alignContent="center" alignItems="center" spacing={1}>
              <Grid item lg={5} md={4} sm={4} xs={12}>
                <TextField
                  type="date"
                  id="data_inicial"
                  value={dataIni}
                  label="Data Inicial"
                  error={!dataIni}
                  onChange={(event) => handleInitialDate(event)}
                  InputLabelProps={{ shrink: true, required: true }}
                />
              </Grid>

              <Grid item lg={5} md={4} sm={4} xs={12}>
                <TextField
                  type="date"
                  id="data_final"
                  value={dataEnd}
                  label="Data Final"
                  error={!dataEnd}
                  onChange={(event) => handleEndDate(event)}
                  InputLabelProps={{ shrink: true, required: true }}
                />
              </Grid>

              <Grid className="grid-filter-button" item lg={2} md={2} sm={6} xs={12}>
                <Tooltip title="Filtrar">
                  <Button className="Btnfilter successBtn" fullWidth variant="contained" onClick={filterClick}><FontAwesomeIcon icon={faFilter}/> Filtrar</Button>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid container justifyContent="center" mt={1}>
              <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />

              <ConfirmDialog
                title={title}
                text={"Deletar"}
                open={openDialog}
                content={contentDialog}
                onClose={handleDeleteCanceled}
                onConfirm={handleDeleteConfirmed}
              />
            </Grid>

          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} id="dataTable">
        <Card className="card-form">
          <CardContent>
            <Tables headers={headers} rows={agronomicPrescription} actions={actions} />
          </CardContent>
        </Card>
      </Grid>

      <ResponsiveDialog
        title={title}
        content={contentModal}
        onClose={modalClose}
        openModal={openModal}
        isFullscreen={fullscreen}
      />

    </Grid >
  );
}

export default ListAgronomicPrescription;

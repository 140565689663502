// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divLocal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.cards-local {
  display: flex;
  font-weight: bold;
  text-align: center;
  flex-direction: column;
  background-color: #ffffff !important;
  color: #009951 !important;
  box-shadow: 2px 2px 5px 0px #ababab7a !important;
  border-radius: 10px !important;
  align-content: center;
  justify-content: center;
  height: 7vh;
}

@media screen and (max-width:900px) {
  .divLocal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Inventory/css/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,qBAAqB;EACrB,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;EACtB,oCAAoC;EACpC,yBAAyB;EACzB,gDAAgD;EAChD,8BAA8B;EAC9B,qBAAqB;EACrB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE;IACE,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,qBAAqB;IACrB,2BAA2B;IAC3B,mBAAmB;EACrB;AACF","sourcesContent":[".divLocal {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  align-content: center;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.cards-local {\n  display: flex;\n  font-weight: bold;\n  text-align: center;\n  flex-direction: column;\n  background-color: #ffffff !important;\n  color: #009951 !important;\n  box-shadow: 2px 2px 5px 0px #ababab7a !important;\n  border-radius: 10px !important;\n  align-content: center;\n  justify-content: center;\n  height: 7vh;\n}\n\n@media screen and (max-width:900px) {\n  .divLocal {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    align-content: center;\n    justify-content: flex-start;\n    align-items: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

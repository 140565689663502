import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';

import "../../../App.css";

const Initial = () => {
  return (
    <Box>
      <Grid container justifyContent="center" marginTop={2}>
        <Grid item className="titles" lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h4">Calculadoras Principais</Typography>
        </Grid>

      </Grid>
    </Box>
  );
}

export default Initial;

/**
 * 
 * const Products = [{ id: 1, title: 'Calculadora de Plantabilidade', link: '', icon: <CalculateIcon className='iconProduct' />, free: true }];
 * <ProductCards product={Products} isLoggedIn={isLoggedIn} />
 */
import React, { useState } from "react";
import { Card, CardContent, Grid, TextField, Typography } from "@mui/material";

const Plantability = () => {

  const [formData, setFormData] = useState({
    populacaoDesejada: "",
    taxaGerminacao: "",
    pms: "",
    espacamentoLinhas: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const quilogramasPorHectare = () => {
    const { populacaoDesejada, taxaGerminacao } = formData;
    if (populacaoDesejada && taxaGerminacao) {
      return (populacaoDesejada * 100) / taxaGerminacao;
    }
    return "";
  };

  const sementesPorMetroQuadrado = () => {
    const { populacaoDesejada, espacamentoLinhas } = formData;
    if (populacaoDesejada && espacamentoLinhas) {
      return populacaoDesejada / (espacamentoLinhas / 10000);
    }
    return "";
  };

  return (
    <Grid container justifyContent="center">

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
        <Card className="card-form">
          <CardContent>

            <Grid container>
              <Typography variant="h6" className="title-accordion">Calculadora</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="populacaoDesejada"
                label="População desejada (plantas por metro quadrado)"
                type="number"
                value={formData.populacaoDesejada}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="taxaGerminacao"
                label="Taxa de germinação"
                type="number"
                value={formData.taxaGerminacao}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="pms"
                label="PMS - Peso por mil sementes (gramas)"
                type="number"
                value={formData.pms}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="espacamentoLinhas"
                label="Espaçamento entre linhas (centímetros)"
                type="number"
                value={formData.espacamentoLinhas}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Sementes por m²"
                type="number"
                value={quilogramasPorHectare()}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Sementes (por m²)"
                type="number"
                value={sementesPorMetroQuadrado()}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

};

export default Plantability;
